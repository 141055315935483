/**
 * useScrollTrigger
 * Set a yOffset Value where the hook will update scroll trigger from false to true and vice versa
 * @param { function } setState useState function that accets a boolean true/false value.
 * @param { number } threshold trigger point in pixels that will toggle the setState function between true and false
 * @param { number } delay debounce delay in milliseconds
 */

import { useEffect, useRef } from "react"

import { debounce } from "debounce"

const useScrollTrigger = (setState:(state:boolean) => boolean, breakPointY: number = 100, delay: number = 100) => {
  
  // using useRef to track scroll position within the hook
  // this helps avoid unecessary re-renders
  const isScrolled = useRef(false)

  const handleScroll = debounce(
    
    () => {
      // TODO: Make sure this works
      const y:number = typeof window !== "undefined" ? window.pageYOffset : 0

        
      if (y >= breakPointY && isScrolled.current === false) {          
        isScrolled.current = true        
        setState(true)
      } else if (y < breakPointY && isScrolled.current === true) {      
        isScrolled.current = false
        setState(false)
      }
    },    
    delay
  )

  /**
   * useLayoutEffect is more effecient for doing simple measurements
   * Passing in no dependencies because it's only setting up a listener and I only want to run this
   * one time to ensure there's only one listener.
   * Remove the listerner with the return statement when it's unounted to avoid a memory leak
   */
  // useLayoutEffect(() => {
  useEffect(() => {

    // Register handleScroll function with the scroll event listener    
    window.addEventListener("scroll", handleScroll, {passive: true,})

    return () => {    
      window.removeEventListener("scroll", handleScroll)
    }
  })

  return isScrolled.current
}

export default useScrollTrigger
