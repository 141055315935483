/**
 * Helper file responsible for building out the Material UI theme palette
 */

const getThemePalette = colors => {
  if (!colors) return null
  return {
    primary: {
      main: colors.primary?.main ?? "#3f51b5",
      light: colors.primary?.light ?? "#7986cb",
      dark: colors.primary?.dark ?? "#303f9f",
      contrastText: colors.primary?.contrastText ?? "#FFF",
    },
    secondary: {
      main: colors.secondary?.main ?? "#f48fb1",
      light: colors.secondary?.light ?? "#f6a5c0",
      dark: colors.secondary?.dark ?? "#aa647b",
      contrastText: colors.secondary?.contrastText ?? "#FFF",
    },
    error: {
      main: colors.error?.main ?? "#f44336",
      light: colors.error?.light ?? "#e57373",
      dark: colors.error?.dark ?? "#d32f2f",
      contrastText: colors.error?.contrastText ?? "#FFF",
    },
    warning: {
      main: colors.warning?.main ?? "#ff9800",
      light: colors.warning?.light ?? "#ffb74d",
      dark: colors.warning?.dark ?? "#f57c00",
      contrastText: colors.warning?.contrastText ?? "#FFF",
    },
    info: {
      main: colors.info?.main ?? "#2196f3",
      light: colors.info?.light ?? "#64b5f6",
      dark: colors.info?.dark ?? "#1976d2",
      contrastText: colors.info?.contrastText ?? "#FFF",
    },
    success: {
      main: colors.success?.main ?? "#4caf50",
      light: colors.success?.light ?? "#81c784",
      dark: colors.success?.dark ?? "#388e3c",
      contrastText: colors.success?.contrastText ?? "#FFF",
    },
    text: {
      primary: colors.text?.primary ?? "rgba(0, 0, 0, 0.87)",      
      secondary: colors.text?.secondary ?? "rgba(0, 0, 0, 0.54)",
      disabled: colors.text?.disabled ?? "rgba(0, 0, 0, 0.38)",
      hint: colors.text?.hint ?? "rgba(0, 0, 0, 0.38)",
    },
  }
}

export default getThemePalette
