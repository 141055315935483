import React from 'react'
import { Script } from 'gatsby'

export interface iScript {
    id: string
    name: string
    active: boolean
    scriptType: 'source' | 'inline'
    loadingStrategy: 'post-hydrate' | 'idle' | 'off-main-thread'
    src: string | null
    inlineScript: string | null
    params: Array<iScriptParams>
}

export interface iScriptParams {
    key: string
    keyType: string
    keyValue: string
}


const GatsbyScriptEmbeds = ({
    scripts = []
}: {
    scripts: Array<iScript>
}) => {

    // If no scripts then return null
    if (scripts.length < 1) return null


    return (
        <>
            {scripts.map((s: iScript) => {
                
                if (!s.active) return null

                // Generate Compoment Props
                const componentProps = s.params.reduce((obj, item) =>
                    Object.assign(obj, { [item.key]: item.keyValue }),
                    {}
                )

                if (s.scriptType === 'inline') {
                    return (
                        <Script
                            id={s.id}
                            strategy={s.loadingStrategy}
                            {...componentProps}
                        >
                            {`${s.inlineScript}`}
                        </Script>
                    )
                }

                if (s.scriptType === 'source') {
                    return (
                        <Script
                            id={s.id}
                            strategy={s.loadingStrategy}
                            src={s.src ?? undefined}
                            {...componentProps}
                        />
                    )
                }
            })}
        </>
    )
}

export default GatsbyScriptEmbeds