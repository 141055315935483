import React from "react"
import TopLayout from "./top-layout"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const WrapWithTheme = ({ element }) => {  

  if (!process.env.NODE_ENV || !process.env.NODE_ENV === "development" || process.env.GATSBY_RECAPTCHA_DISABLE === "true") {  
    return <TopLayout>{element}</TopLayout>
  } else {
    return (
      <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={process.env.GATSBY_RECAPTCH_SITE_KEY}
        scriptProps={{ async: true, defer: true, appendTo: "body" }}
      >
        <TopLayout>{element}</TopLayout>
      </GoogleReCaptchaProvider>
    )
  }
}

export default WrapWithTheme
