import { useEffect, useState } from 'react'

const useLoaded = () => {

  const [ loaded, setLoaded ] = useState(false)

  const handleLoad = () => {
    setLoaded(true)
  }

  useEffect(() => {
    
    window.addEventListener('load', handleLoad, {passive: true})
    return () => {
      window.removeEventListener('load', handleLoad)
    }
  }, [])

  return loaded
}

export default useLoaded