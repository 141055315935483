/**
 * Helper file responsible for building out the Material UI theme palette
 */

 const getThemeTypography = typography => {
  if (!typography) return null
  
  return {
    hero: {
      fontFamily: typography.hero?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.hero?.fontWeight ?? 300,
      fontSize: typography.hero?.fontSize ?? "6rem",
      lineHeight: typography.hero?.lineHeight ?? 1.167,      
      letterSpacing: typography.hero?.letterSpacing ?? "0.01562em",
      color: typography.hero?.color ?? null
    },
    nav: {
      fontFamily: typography.nav?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.nav?.fontWeight ?? 400,
      fontSize: typography.nav?.fontSize ?? "0.875rem",
      lineHeight: typography.nav?.lineHeight ?? 1.43,      
      letterSpacing: typography.nav?.letterSpacing ?? "0.01071em",
      color: typography.nav?.color ?? null
    },
    subNav: {
      fontFamily: typography.subNav?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.subNav?.fontWeight ?? 400,
      fontSize: typography.subNav?.fontSize ?? "0.875rem",
      lineHeight: typography.subNav?.lineHeight ?? 1.43,      
      letterSpacing: typography.subNav?.letterSpacing ?? "0.01071em",
      color: typography.subNav?.color ?? null
    },
    h1: {
      fontFamily: typography.h1?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.h1?.fontWeight ?? 300,
      fontSize: typography.h1?.fontSize ?? "6rem",
      lineHeight: typography.h1?.lineHeight ?? 1.167,      
      letterSpacing: typography.h1?.letterSpacing ?? "0.01562em",
      color: typography.h1?.color ?? null
    },
    h2: {
      fontFamily: typography.h2?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.h2?.fontWeight ?? 300,
      fontSize: typography.h2?.fontSize ?? "3.75rem",
      lineHeight: typography.h2?.lineHeight ?? 1.2,      
      letterSpacing: typography.h2?.letterSpacing ?? "0.00833em",
      color: typography.h2?.color ?? null
    },
    h3: {
      fontFamily: typography.h3?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.h3?.fontWeight ?? 400,
      fontSize: typography.h3?.fontSize ?? "3rem",
      lineHeight: typography.h3?.lineHeight ?? 1.167,      
      letterSpacing: typography.h3?.letterSpacing ?? "0em",
      color: typography.h3?.color ?? null
    },
    h4: {
      fontFamily: typography.h4?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.h4?.fontWeight ?? 400,
      fontSize: typography.h4?.fontSize ?? "2.125rem",
      lineHeight: typography.h4?.lineHeight ?? 1.235,      
      letterSpacing: typography.h4?.letterSpacing ?? "0.00735em",
      color: typography.h4?.color ?? null
    },
    h5: {
      fontFamily: typography.h5?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.h5?.fontWeight ?? 400,
      fontSize: typography.h5?.fontSize ?? "1.5rem",
      lineHeight: typography.h5?.lineHeight ?? 1.334,      
      letterSpacing: typography.h5?.letterSpacing ?? "0em",
      color: typography.h5?.color ?? null
    },
    h6: {
      fontFamily: typography.h6?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.h6?.fontWeight ?? 500,
      fontSize: typography.h6?.fontSize ?? "1.25rem",
      lineHeight: typography.h6?.lineHeight ?? 1.6,      
      letterSpacing: typography.h6?.letterSpacing ?? "0.00735em",
      color: typography.h6?.color ?? null
    },
    subtitle1: {
      fontFamily: typography.subtitle1?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.subtitle1?.fontWeight ?? 400,
      fontSize: typography.subtitle1?.fontSize ?? "1rem",
      lineHeight: typography.subtitle1?.lineHeight ?? 1.75,      
      letterSpacing: typography.subtitle1?.letterSpacing ?? "0.00938em",
      color: typography.subtitle1?.color ?? null
    },
    subtitle2: {
      fontFamily: typography.subtitle2?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.subtitle2?.fontWeight ?? 500,
      fontSize: typography.subtitle2?.fontSize ?? "0.875rem",
      lineHeight: typography.subtitle2?.lineHeight ?? 1.57,      
      letterSpacing: typography.subtitle2?.letterSpacing ?? "0.00714em",
      color: typography.subtitle2?.color ?? null
    },
    body1: {
      fontFamily: typography.body1?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.body1?.fontWeight ?? 400,
      fontSize: typography.body1?.fontSize ?? "1rem",
      lineHeight: typography.body1?.lineHeight ?? 1.5,      
      letterSpacing: typography.body1?.letterSpacing ?? "0.00938em",
      color: typography.body1?.color ?? null
    },
    body2: {
      fontFamily: typography.body2?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.body2?.fontWeight ?? 400,
      fontSize: typography.body2?.fontSize ?? "0.875rem",
      lineHeight: typography.body2?.lineHeight ?? 1.43,      
      letterSpacing: typography.body2?.letterSpacing ?? "0.01071em",
      color: typography.body2?.color ?? null
    },
    button: {
      fontFamily: typography.button?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.button?.fontWeight ?? 400,
      fontSize: typography.button?.fontSize ?? "0.875rem",
      lineHeight: typography.button?.lineHeight ?? 1.43,      
      letterSpacing: typography.button?.letterSpacing ?? "0.01071em",
      color: typography.button?.color ?? null
    },
    caption: {
      fontFamily: typography.caption?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.caption?.fontWeight ?? 400,
      fontSize: typography.caption?.fontSize ?? "0.875rem",
      lineHeight: typography.caption?.lineHeight ?? 1.43,      
      letterSpacing: typography.caption?.letterSpacing ?? "0.01071em",
      color: typography.caption?.color ?? null
    },
    overline: {
      fontFamily: typography.overline?.fontFamily ?? "Helvetica, Arial, sans-serif",
      fontWeight: typography.overline?.fontWeight ?? 400,
      fontSize: typography.overline?.fontSize ?? "0.875rem",
      lineHeight: typography.overline?.lineHeight ?? 1.43,      
      letterSpacing: typography.overline?.letterSpacing ?? "0.01071em",
      color: typography.overline?.color ?? null
    },
  }
}

export default getThemeTypography
