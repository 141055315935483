import json5 from 'json5'

const handleSX = (sx: string) => {
    try {
      const styles = json5.parse(sx)
      return styles
    } catch (error) {
      // console.log(error)
      return '{}'
    }
  }

  export default handleSX