import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
interface ITrackEventProps {
  category: string,
  action: string,
  label: string,
  value: number | null
}

const trackEvent = (event: ITrackEventProps) => {
  
  if (!event) return null
  
  
  trackCustomEvent(
    {
      category: event?.category ?? 'link',
      action: event?.action ?? 'click',
      label: event?.label ?? 'undefined',
      value: event?.value ?? 0
    }
  )

}

export default trackEvent
