import { useEffect, useState } from "react"

const useIsMobile = () => {
  
  const [width, setWidth] = useState(typeof window === `undefined` ? 0 : window.innerWidth)
  
  const handleWindowResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize, {passive: true,})
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  return width <= 768
}

export default useIsMobile
