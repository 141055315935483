/**
 * Component get the customcss documents from sanity CMS and inserts them into the site.
 */
import React from "react"
import { useStaticQuery, graphql } from "gatsby"


const CustomStyles = () => {
  const customStyles = useStaticQuery(
    graphql`
      query {
        allSanityCss(filter: { active: { eq: true } }) {
          edges {
            node {
              customCSS
            }
          }
        }
      }
    `
  )
  
  const allCSS = customStyles?.allSanityCss?.edges
  if (!allCSS) return null

  return (    
      <style type="text/css">
        {allCSS.map((css:any) => {
          return `${css.node.customCSS}`
        })}
      </style>    
  )
}

export default CustomStyles
