/**
 * Function to ensure that a valid object is always given to the 
 * animation variant. This will avoid errors if the entry is incorrect
* or malformed while in edit mode or procution for that matter :)
*/

import JSON5 from "json5"

const parseJSON5 = (data: string) => {

    try {
        const js5 = JSON5.parse(data)
        return js5
    } catch {
        return {}
    }
}

export default parseJSON5