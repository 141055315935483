const getGridValue = (grid, gridIndex) => {
  // if there is no grid then return null
  if (!grid) return true

  // if there is a grid then check the grid index for a value
  let gridValue = grid[gridIndex]

  // if the current index is not available then get the last value available
  if (grid.length <= gridIndex) gridValue = grid[grid.length - 1]

  // Catch 'auto' string option
  if (gridValue === "auto") return "auto"
  // Catch true boolean value
  if (gridValue === "true") return true
  // Catch the numbers and return a number value rather than a string otherwise return true
  return gridValue ? parseInt(gridValue, 10) : true
}

export default getGridValue