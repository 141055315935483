/**
 * Helper file responsible for building out the Material UI theme palette
 */

const getThemeShape = shape => {
  if (!shape) return null

  return {
    borderRadius: shape.borderRadius ?? 4,
  }
}

export default getThemeShape
