/**
 * Component that handles anchor scrolls
 * Include this in a page level component and pass it a browser location object
 *
 * Purpose
 * When the page route changes and an anchor link is included, or when you visit a page directyly that has an anchor link,
 *  - The page needs to completely load
 *  - Layout shifts need to finish so that all components are at their final location
 *  - Browser needs to scroll to the desired point on the page
 */

import { useEffect } from "react"
import scrollToElement from "scroll-to-element"
import { useLoaded } from "hooks"

const ScrollHandler = ({ location }) => {
  const loaded = useLoaded()
  const { hash } = location

  useEffect(() => {
    // check for hash in url
    if (hash && loaded) {
      setTimeout(() => scrollToElement(hash), 500)
    }
  }, [location, loaded, hash])

  return null
}

export default ScrollHandler
