/**
 * Helper file responsible for building out the Material UI theme palette
 */

const getThemeOverrides = overrides => {
  if (!overrides) return null
  
  return {
    overrides
  }
}

export default getThemeOverrides
