/**
 * useScrollTrigger
 * Set a yOffset Value where the hook will update scroll trigger from false to true and vice versa
 * @param { function } setState useState function that accets a boolean true/false value.
 * @param { number } threshold trigger point in pixels that will toggle the setState function between true and false
 * @param { number } delay debounce delay in milliseconds
 */

import { useEffect, useRef } from "react"
import { debounce } from "debounce"

const useBreakpointTrigger = (setState:(state:boolean) => boolean, breakPointX: number = 960, delay: number = 100) => {
  // using useRef to track scroll position within the hook
  // this helps avoid unecessary re-renders
  const isScrolled = useRef(false)
  
  const handleResize = debounce(    
    () => {
      const x: number = typeof window !== "undefined" ? window.innerWidth : 1920
      if (x <= breakPointX && isScrolled.current === false) {        
        // Set isScrolled.current to true
        isScrolled.current = true
        // Set the state of the passed in state function to true
        // This will trigger a re-render on the component calling the hook
        setState(true)
      } else if (x > breakPointX && isScrolled.current === true) {        
        // do the opposite
        isScrolled.current = false
        setState(false)
      }
    }
    ,delay
  )

  /**
   * useLayoutEffect is more effecient for doing simple measurements
   * Passing in no dependencies because it's only setting up a listener and I only want to run this
   * one time to ensure there's only one listener.
   * Remove the listerner with the return statement when it's unounted to avoid a memory leak
   */
  // useLayoutEffect(() => {
  useEffect(() => {
    
    // Execute handleResize immediately to set the initial window size.
    // The listener does not execute until the window actually changes size.
    // This will ensure that the initial state is set correctly
    handleResize()

    // Register handleScroll function with the scroll event listener
    window.addEventListener("resize", handleResize, {passive: true})
    
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  return isScrolled.current
}

export default useBreakpointTrigger
