/**
 * Gets the RGBA Color Values from the custom color component
 */

import { useMemo } from "react"
export interface iColorProps {
  rgb: {
    r: string
    g: string
    b: string
    a: string
  }
}

const getRGBA = (color:iColorProps) => {
  if (!color) return null
  return `rgba(${color.rgb?.r},${color.rgb?.g},${color.rgb?.b},${color.rgb?.a})`
}

export default getRGBA